import axios from "axios";
import axiosInstance from "../../../services/AxiosInstance";

const deshBoardApiURL = "https://api.baraatco.com/api/v1/admin/home";
const userListApiUrl = "https://api.baraatco.com/api/v1/admin/user";
const UserDetailsUrl = "https://api.baraatco.com/api/v1/admin/user";
const servicesProviderListUrl = "https://api.baraatco.com/api/v1/getVendor";
const ServicesProviderDetailsUrl = "https://api.baraatco.com/api/v1/admin/user";
const CategoryListApiUrl = "https://api.baraatco.com/api/v1/category";
const BlockUserUrl = "https://api.baraatco.com/api/v1/admin/user";
const AddCategoryUrl = "https://api.baraatco.com/api/v1/category";
const AddServiceUrl = "admin/createServices";
const updateCategoryUrl = "https://api.baraatco.com/api/v1/category";
const updateServiceUrl = "admin/editService";
const BlockServiceUrl = "https://api.baraatco.com/api/v1/admin/user";

// const role="";
const token = localStorage.getItem("bermudaToken");

// ===================getAPIS=============================
export async function dashboardApi() {
  const response = await axiosInstance.get(`admin/dashboard`, {});
  return response;
}
export async function userManagementList(itemsPerPage, currentPage, search) {
  const response = await axiosInstance.get(
    `admin/users?limit=${itemsPerPage}&page=${currentPage}&search=${search}`,
    {}
  );
  return response;
}
export async function complaintListApi(itemsPerPage, currentPage, type) {
  const response = await axiosInstance.get(
    `admin/getComplaints?limit=${itemsPerPage}&page=${currentPage}&type=${type}`,
    {}
  );
  return response;
}

export async function viewUserDetails(id) {
  const response = await axiosInstance.get(`${UserDetailsUrl}/${id}`, {});
  return response;
}

export async function servicesProviderListApi(itemsPerPage, currentPage) {
  const response = await axiosInstance.get(
    `getVendor?limit=${itemsPerPage}&offset=${currentPage}`,
    {}
  );
  return response;
}

export async function servicesProviderDetailsApi(id) {
  const response = await axios.get(`${ServicesProviderDetailsUrl}/${id}`, {
    headers: {
      "Content-Type": "application/json",
      Authorization: `${token}`,
    },
  });
  return response;
}

export async function categoryListApi(itemsPerPage, currentPage) {
  const response = await axiosInstance.get(
    `category?limit=${itemsPerPage}&offset=${currentPage}`,
    {}
  );
  return response;
}

export async function serviceListApi(itemsPerPage, currentPage) {
  const response = await axiosInstance.get(
    `admin/servicelist?limit=${itemsPerPage}&page=${currentPage}`,
    {}
  );
  return response;
}

// ================putAPI==========================
// const data={}
export async function blockUserApi(id) {
  // console.log(id,"id i api")
  const data = { userId: id };
  const response = await axiosInstance.put("admin/userAction", data);
  return response;
}

export async function onChangeAdminPasswordApi(newPassword, oldPassword) {
  const data = { newPassword, oldPassword };
  const response = await axiosInstance.put(`admin/chnagePassword`, data);
  return response;
}

export async function updateComplaintApi(complaintId, status) {
  const postData = { complaintId, status };

  // console.log(formData,"formData i api")
  // const data={status:status}
  const response = await axiosInstance.put(`admin/complaintAction`, postData);
  return response;
}

export async function editServiceApi(formData) {
  const response = await axiosInstance.put(updateServiceUrl, formData, {});
  return response;
}
export async function editSubAdminApi(data) {
  const response = await axiosInstance.put("admin/editProfileSubAdmin", data);
  return response;
}

export async function policyApi(privacyPolicy) {
  const data = { privacyPolicy };
  const response = await axiosInstance.post(`admin/createPrivacyPolicy`, data);
  return response;
}

export async function termApi(termsAndConditions) {
  const data = { termsAndConditions };
  const response = await axiosInstance.post(
    `admin/createTermsAndConditions`,
    data
  );
  return response;
}
export async function aboutApi(about) {
  const data = { aboutUs: about };
  const response = await axiosInstance.post(`admin/aboutus`, data);
  return response;
}
// ======================post=============================
export async function addCategoryApi(name, file) {
  console.log(name, file);
  const formData = new FormData();
  formData.append("file", file);
  formData.append("category", name);
  console.log(formData, "catdata i api");
  // const data={AddCategoryData};
  const response = await axiosInstance.post(AddCategoryUrl, formData, {});
  return response;
}

export async function addServiceApi(data) {
  const response = await axiosInstance.post(AddServiceUrl, data, {});
  return response;
}
export async function addMultiVendorApi(formData) {
  // const data = { vendors: formData };
  const response = await axiosInstance.post(
    "admin/addbulkVendor",
    formData,
    {}
  );
  return response;
}
export async function addSubAdminApi(data) {
  const response = await axiosInstance.post("admin/createSubAdmins", data);
  return response;
}

export async function downloadReportApi(data) {
  const postData = {
    firstName: data.UserDetails.FirstName,
    lastName: data.UserDetails.LastName,
    phoneNumber: data.UserDetails.PhoneNumber,
    email: data.UserDetails.Email,
    search: "",
    startDate: "",
    endDate: "",
    disabilityInformation: {
      typeOfDisability: data.Disability.TypeOfDisability,
      severity: data.Disability.TypeOfDisability,
      onsetOfDisability: data.Disability.OnsetOfDisability,
    },
    education: {
      highestEductionLevel: data?.EducationOccupation.HighestEducationLevel,
      employementStatus: data.EducationOccupation.EmploymentStatus,
      occupationalNeeds: data.EducationOccupation.EducationOccupationalNeeds,
    },
    healthInformation: {
      medicalTreatment: data.HealthInformation.OngoingMedicalTreatment,
      insuranceProvider: data.HealthInformation.HealthInsuranceProvider,
    },
    socioeconomicInformation: {
      houseHoldCompositon: data.SocioeconomicInformation.HouseholdComposition,
      livingConditions: data.SocioeconomicInformation.LivingConditions,
      houseHoldIncome: data.SocioeconomicInformation.HouseholdIncome,
    },
    supportAndAssistance: {
      assistanceReceived: data.SupportAssistance.AssistanceReceived,
      assistiveDevices: data.SupportAssistance.AssistiveDevices,
      unmetAssistanceNeeds: data.SupportAssistance.UnmetAssistanceNeeds,
      programParticipation: data.SupportAssistance.ProgramParticipation,
    },
    additionalInformation: {
      supportRelationship: data.AdditionalInformation.SupportRelationship,
      relationshipToContact: data.AdditionalInformation.RelationshipToContact,
      supportContactNumber: data.AdditionalInformation.SupportContactNumber,
      supportEmail: data.AdditionalInformation.SupportEmail,
      supportPerson: data.AdditionalInformation.SupportPerson,
      emergencyContactNumber: data.AdditionalInformation.EmergencyContactNumber,
      emergencyContactName: data.AdditionalInformation.EmergencyContactName,
      emailAddress: data.AdditionalInformation.EmailAddress,
    },
  };

  const response = await axiosInstance.put("admin/exportData", postData);
  return response;
}

//===============Delete============================================================
export async function deleteCategoryApi(id) {
  const response = await axiosInstance.delete(`category/${id}`);
  return response;
}
export async function deleteUserApi(id) {
  const response = await axiosInstance.delete(`admin/deleteUser?userId=${id}`);
  return response;
}
export async function deleteAdminApi(id) {
  const data = { id: id };
  const response = await axiosInstance.delete(`admin/deleteSubAdmin`, data);
  return response;
}
export async function deleteServiceApi(id) {
  const response = await axiosInstance.delete(
    `admin/deleteService?serviceId=${id}`
  );
  return response;
}
