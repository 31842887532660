import React from "react";
import { servicesProviderDetailsApi } from "../components/APIs/Api";
import { variable } from "../components/Variable";
import { useState, useEffect } from "react";
import { Card } from "react-bootstrap";
import { Link } from "react-router-dom";
import Lottie from "lottie-react";
import animationData from "..//../images/loding.json";
import defaultImg from "../../images/defaulImg.jpg";
import { de, deAT } from "date-fns/locale";

export default function ServiceProviderDetails() {
  const [details, setDetails] = useState();
  const [loader, setLoader] = useState(true);


  const onImageError = (e) => {
    e.target.src = defaultImg;
  };

  async function serviceDetails() {
    const res = await servicesProviderDetailsApi(variable.id);
    setDetails(res);
    setLoader(false);
  }

  useEffect(() => {
    serviceDetails();
  }, []);

  const item = details?.data?.data;
  return (
    <>
      {loader ? (
        <div className="d-flex justify-content-center">
          <Lottie style={{ height: "400px" }} animationData={animationData} />
        </div>
      ) : (
        <div>
          <div className="page-titles">
            <h4>Service Provider Details</h4>
            <ol className="breadcrumb">
              <li className="breadcrumb-item">
                <Link to="user-management">Service</Link>
              </li>
              <li className="breadcrumb-item active">
                <Link>Service Provider Details</Link>
              </li>
            </ol>
          </div>
          {/* <Col> */}
          <Card style={{ width: "70%" }}>
            <Card.Header>
              <div className="d-flex" style={{ gap: "1rem", flexGrow: "1" }}>
                <div>
                  <img
                    src={item.image || defaultImg}
                    width={110}
                    height={110}
                    className="profile-photo"   
                    onError={onImageError}               />
                </div>
                <div className="text-left" style={{ flex: "1" }}>
                  <h3 className="text-color ">{item.name}</h3>
                  <div className="d-flex justify-content-between align-items-center">
                    <h5>Email</h5>
                    <p>{item.email}</p>
                  </div>
                  <div className="d-flex justify-content-between align-items-center">
                    <h5 style={{ margin: "0" }}>phone</h5>
                    <p style={{ margin: "0" }}>
                      <span>{item.countryCode}</span>
                      {item.number}
                    </p>
                  </div>
                </div>
              </div>
            </Card.Header>
            <Card.Body>
              <div className="text-black">
                <div className="d-flex justify-content-between align-items-center pb-3">
                  <h5>Created At</h5>
                  <p className="py-2">{item?.created_at.length > 10
                              ? item?.created_at.slice(0, 10)
                              : item?.created_at}</p>
                </div>

                <div className="d-flex justify-content-between align-items-center pb-3">
                  <h5>Address</h5>
                  <p className="">{item.address}</p>
                </div>
              </div>
            </Card.Body>
          </Card>
          {/* </Col> */}
        </div>
      )}
    </>
  );
}
