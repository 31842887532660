import React from "react";

export default function FilterSearch({
  // setSearch,
  // setStartDate,
  // setEndDate,
  FilterOption,
  setFilterType,
  Type,
  limitValue,
  title,
}) {
  return (
    <>
      <div
        className="d-flex align-items-center justify-content-between "
        style={{ flexWrap: "wrap" }}
      >
        {/* {Type === "off" && type === "search" ? (
          <div></div>
        ) : (
          <div className="d-flex ">
            <div class="input-group">
              <div class="">
                <label>Search</label>
                <input
                  style={{
                    height: "45px",
                    border: "1.3px solid #393838",
                    width: "15rem ",
                  }}
                  type="text"
                  id="search-box"
                  class="form-control"
                  placeholder="Search"
                  onChange={(e) => setSearch(e.target.value)}
                />
              </div>
            </div>
          </div>
        )} */}
        {/* {Type === "off" && type === "search" ? (
          <div></div>
        ) : (
          <div className="d-flex ">
            <div class="input-group">
              <div class="">
                <label>City</label>
                <input
                  style={{
                    height: "45px",
                    border: "1.3px solid #393838",
                    width: "15rem ",
                  }}
                  type="text"
                  id="search-box"
                  class="form-control"
                  placeholder="Enter City"
                  onChange={(e) => setSearch(e.target.value)}
                />
              </div>
            </div>
          </div>
        )} */}

        {Type === "off" ? (
          <div></div>
        ) : (
          <div>
            {/* <label>{title}</label> */}
            {/* <select
              className="search-input"
              style={{
                textAlign: "start",
                fontWeight: "bold",
                marginLeft: "5px",
              }}
              value={limitValue}
              // class="d-flex justify-content-start text-secondary border border-secondary btn btn-sm"
              onChange={(e) => {
                setFilterType(e.target.value);
              }}
            >
              {FilterOption.map((item) => (
                <option
                  className="filter-options"
                  value={item.value}
                  key={item}
                >
                  {item.label}
                </option>
              ))}
            </select> */}
            <select
              style={{
                textAlign: "start",
                // fontWeight: "bold",
                // marginLeft: "5px",
              }}
              class="d-flex justify-content-start text-secondary border border-secondary btn btn-xs form-control"
              onChange={(e) => setFilterType(e.target.value)}
              value={limitValue}
            >
              {FilterOption?.map((item) => (
                <option className="form-control" value={item?.value} key={item}>
                  {item?.label}
                </option>
              ))}
            </select>
          </div>
        )}
        {/* 
        {Type === "off" ? (
          <div></div>
        ) : (
          <div className="d-flex date-filter" style={{ gap: "20px" }}>
            <div className="d-flex flex-column">
              <label>Start date</label>
              <input
                type="date"
                onChange={(e) => setStartDate(e.target.value)}
              />
            </div>
            <div className="d-flex flex-column">
              <label>End date</label>
              <input type="date" onChange={(e) => setEndDate(e.target.value)} />
            </div>
          </div>
        )} */}
      </div>
    </>
  );
}
