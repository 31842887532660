export default function Button ({loader}){
    return(
    <button className={`btn btn-sm ${loader ? 'disabled' : ''}`} disabled={loader} type="submit" style={ 
        loader
            ? { pointerEvents: "none", opacity: 0.5 }
            : { pointerEvents: "auto" }
    }>
        {loader ? (<div className="d-flex align-items-center justify-content-center " style={{gap:"5px"}}>
            <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
            Loading...
        </div>)
            : ('Save')}
    </button>
    );
}