import React, { useState } from "react";
import { Modal, Button, Form, Spinner } from "react-bootstrap";
import toast from "react-hot-toast";
import { editServiceApi } from "../components/APIs/Api";

const ServiceModal = ({
  show,
  handleClose,
  title,
  api,
  selectedService,
  successMessage,
  buttonName,
  refresh,
}) => {
  const [formData, setFormData] = useState({
    image: "",
    serviceType: selectedService?.type,
  });
  const [loader, setLoader] = useState(false);
  const [errors, setErrors] = useState({
    serviceType: false,
  });

  const handleChange = (e) => {
    const { name, value, files } = e.target;
    setFormData({
      ...formData,
      [name]: name === "image" ? files[0] : value,
    });
    setErrors({
      ...errors,
      [name]: false,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const newErrors = {
      serviceType: !formData.serviceType.trim(),
    };
    setErrors(newErrors);
    if (!newErrors.name) {
      setLoader(true);
      console.log(formData);

      const data = new FormData();
      if (formData.image) {
        data.append("image", formData.image);
      } else {
        data.append("image", "null");
      }
      if (title === "Update Service") {
        data.append("serviceId", selectedService?._id);
      }
      data.append("type", formData.serviceType);

      try {
        const response = await api(data);
        console.log(response);
        toast.success(successMessage);
        setLoader(false);
        handleClose();
        refresh();
      } catch (error) {
        toast.error(
          error?.response?.data?.data || error?.response?.data?.message
        );
        setLoader(false);
      }
    }
  };

  return (
    <Modal show={show} onHide={handleClose} centered>
      <Modal.Header closeButton>
        <Modal.Title>{title}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form onSubmit={handleSubmit}>
          {/* Image Field */}
          <Form.Group className="mb-3" controlId="formImage">
            <Form.Label>Image</Form.Label>
            <Form.Control
              className="form-control"
              type="file"
              name="image"
              accept=".jpg, .jpeg, .png"
              onChange={handleChange}
            />
          </Form.Group>

          {/* Service Type Field */}
          <Form.Group className="mb-3" controlId="formServiceType">
            <Form.Label>Service Type</Form.Label>
            <Form.Control
              type="text"
              name="serviceType"
              placeholder="Enter Service Type"
              value={formData.serviceType}
              onChange={handleChange}
              isInvalid={errors.serviceType}
            />
            <Form.Control.Feedback type="invalid">
              Service type is required.
            </Form.Control.Feedback>
          </Form.Group>
          <div className="d-flex justify-content-end">
            <Button variant="success" type="submit">
              {loader ? (
                <Spinner animation="border" size="sm" title="Loading.." />
              ) : (
                `${buttonName}`
              )}
            </Button>
          </div>
        </Form>
      </Modal.Body>
    </Modal>
  );
};

export default ServiceModal;
