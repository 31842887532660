import React, { useEffect, useState } from "react";
import { Dropdown, Card, Table, Badge, Col } from "react-bootstrap";
import PageTitle from "../layouts/PageTitle";
import { complaintListApi, updateComplaintApi } from "../components/APIs/Api";
import Lottie from "lottie-react";
import animationData from "../../images/loding.json";
import toast, { Toaster } from "react-hot-toast";
import Pagination from "../components/Pagination";
import FilterSearch from "../components/FilterSearch";

const svg1 = (
  <svg width="20px" height="20px" viewBox="0 0 24 24" version="1.1">
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <rect x="0" y="0" width="24" height="24"></rect>
      <circle fill="#198754" cx="5" cy="12" r="2"></circle>
      <circle fill="#198754" cx="12" cy="12" r="2"></circle>
      <circle fill="#198754" cx="19" cy="12" r="2"></circle>
    </g>
  </svg>
);
const limitOptions = [
  { value: "5", label: "5" },
  { value: "10", label: "10" },
  { value: "20", label: "20" },
  { value: "40", label: "40" },
  { value: "60", label: "60" },
  { value: "80 ", label: "80 " },
  { value: "100", label: "100" },
];
const filterComplaint = [
  { value: "", label: "All" },
  { value: "Pending", label: "Open" },
  { value: "Resolve", label: "Closed" },
  { value: "Rejected", label: "Reject" },
];
export default function ComplaintList(props) {
  const [loader, setLoader] = useState(true);
  const [currentPage, setCurrentPage] = useState(0);
  const [usersList, setUsersList] = useState([]);
  const [type, setType] = useState("");
  const [itemsPerPage, setItemsPerPage] = useState(10);

  const scrollToTop = () => {
    window.scrollTo(0, 0);
  };

  async function getComplaintList() {
    setLoader(true);
    try {
      const res = await complaintListApi(itemsPerPage, currentPage, type);
      setUsersList(res);
    } catch (err) {
      toast.error(err.response?.data?.message);
    } finally {
      setLoader(false);
    }
  }

  // async function handleDeleteUser(id) {
  //   try {
  //     const res = await deleteAdminApi(id);
  //     getComplaintList();
  //   } catch (error) {
  //     console.error("Something bad happened");
  //     console.error(error.response);
  //     throw new Error(error);
  //   }
  // }

  // function deleteUserPromise(id) {
  //   toast.promise(handleDeleteUser(id), {
  //     loading: "Saving...",
  //     success: "Delete Successfully!",
  //     error: "try latter.",
  //   });
  // }

  async function blockUser(id, status) {
    setLoader(true);
    try {
      const res = await updateComplaintApi(id, status);
      if (res?.data?.data?.status === "Resolve") {
        toast.success("Complaint Resolved successfully");
      } else {
        toast.error("Complaint Rejected successfully");
      }
    } catch (err) {
      toast.error(err?.response?.data?.message);
    } finally {
      getComplaintList();
      setLoader(false);
    }
  }

  const totalItems = usersList?.data?.data.total;
  const totalPages = Math.ceil(totalItems / itemsPerPage);

  useEffect(() => {
    getComplaintList();
    scrollToTop();
  }, [itemsPerPage, currentPage, type]);

  return (
    <>
      <div>
        <div className="">
          <PageTitle activeMenu="Complaint List" motherMenu="Complaints" />
          <div className="p-3">
            <FilterSearch
              FilterOption={filterComplaint}
              setFilterType={setType}
              limitValue={type}
            />
          </div>
        </div>
        <Col>
          {loader ? (
            <div>
              <Lottie
                style={{
                  height: "200px",
                  marginTop: "45px",
                  padding: "30px",
                }}
                animationData={animationData}
              />
            </div>
          ) : (
            <Card>
              <Card.Body>
                <Table>
                  <thead style={{ color: "black" }}>
                    <tr>
                      <th>
                        <strong>NAME</strong>
                      </th>
                      <th>
                        <strong>Services</strong>
                      </th>
                      <th>
                        <strong>Date</strong>
                      </th>
                      <th>
                        <strong>Address</strong>
                      </th>
                      <th>
                        <strong>STATUS</strong>
                      </th>
                      <th>
                        <strong>Action</strong>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {usersList?.data?.data?.complaints?.map((item, i) => (
                      <tr key={item.id}>
                        <td>{item?.userName}</td>
                        <td>{item?.service}</td>
                        <td>{item?.date}</td>
                        <td>
                          {item?.address.length > 30
                            ? item?.address.slice(0, 20) + "..."
                            : item?.address}
                        </td>
                        <td>
                          {item?.status === "open" && (
                            <Badge variant="info light">{item?.status}</Badge>
                          )}
                          {item?.status === "close" && (
                            <Badge variant="success light">
                              {item?.status}
                            </Badge>
                          )}
                          {item?.status === "Rejected" && (
                            <Badge variant="warning light">
                              {item?.status}
                            </Badge>
                          )}
                          {item?.status === "Deleted" && (
                            <Badge variant="danger light">{item?.status}</Badge>
                          )}
                        </td>
                        <td>
                          <Dropdown>
                            <Dropdown.Toggle
                              variant="info light"
                              className="light sharp btn btn-info i-false"
                            >
                              {svg1}
                            </Dropdown.Toggle>
                            <Dropdown.Menu>
                              {item?.status !== "Deleted" && (
                                <>
                                  {item?.status !== "close" && (
                                    <Dropdown.Item
                                      onClick={() => blockUser(item?._id, true)}
                                    >
                                      Closed
                                    </Dropdown.Item>
                                  )}
                                  {item?.status !== "Rejected" && (
                                    <Dropdown.Item
                                      onClick={() =>
                                        blockUser(item?._id, false)
                                      }
                                    >
                                      Reject
                                    </Dropdown.Item>
                                  )}
                                </>
                              )}

                              <Dropdown.Item
                                onClick={() => {
                                  props.history.push({
                                    pathname: "/user-details",
                                    state: {
                                      data: item,
                                      api: "getComplaintList",
                                    },
                                  });
                                }}
                              >
                                Incidence Details
                              </Dropdown.Item>
                            </Dropdown.Menu>
                          </Dropdown>
                        </td>{" "}
                      </tr>
                    ))}
                  </tbody>
                </Table>

                {usersList?.data?.data?.subAdmins?.length === 0 && (
                  <div className="justify-content-center d-flex my-5 ">
                    Sorry, Data Not Found!
                  </div>
                )}
                {usersList?.length !== 0 && (
                  <div className="card-footer clearfix">
                    <div className="d-flex justify-content-between align-items-center">
                      <div className="dataTables_info">
                        Page {currentPage * itemsPerPage + 1} to{" "}
                        {totalItems > (currentPage + 1) * itemsPerPage
                          ? (currentPage + 1) * itemsPerPage
                          : totalItems}{" "}
                        of {totalItems} entries
                      </div>
                      <div
                        className="d-flex align-items-center"
                        style={{ gap: "10px" }}
                      >
                        <FilterSearch
                          FilterOption={limitOptions}
                          setFilterType={setItemsPerPage}
                          limitValue={itemsPerPage}
                        />
                        <Pagination
                          pageCount={totalPages}
                          pageValue={currentPage}
                          setPage={setCurrentPage}
                        />
                      </div>
                    </div>
                  </div>
                )}
              </Card.Body>
            </Card>
          )}
        </Col>
      </div>
    </>
  );
}
