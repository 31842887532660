import axios from "axios";
import swal from "sweetalert";
import { loginConfirmedAction, logout } from "../store/actions/AuthActions";

export function signUp(email, password) {
  //axios call

  const postData = {
    email,
    password,
    returnSecureToken: true,
  };

  return axios.post(
    `https://identitytoolkit.googleapis.com/v1/accounts:signUp?key=AIzaSyD3RPAp3nuETDn9OQimqn_YF6zdzqWITII`,
    postData
  );
}

export async function login(email, password) {
  //   let role = "";
  //   if (password) {
  //     role = "admin";
  //   } else {
  //     role = "subAdmin";
  //   }
  const postData = {
    email,
    password,
  };

  return await axios.post(
    `https://api.islandfixbahamas.com/admin/login`,
    postData
  );
}

export function formatError(errorMessage) {
  console.log(errorMessage, "errormasg");
  if (errorMessage) {
    swal("Oops", errorMessage, "error");
  }
}

export function saveTokenInLocalStorage(tokenDetails) {
  console.log(tokenDetails, "logintoken");
  localStorage.setItem("IslandToken", tokenDetails);
}

export function runLogoutTimer(dispatch, timer, history) {
  setTimeout(() => {
    dispatch(logout(history));
  }, timer);
}

export function checkAutoLogin(dispatch, history) {
  const tokenDetailsString = localStorage.getItem("IslandToken");
  if (!tokenDetailsString) {
    dispatch(logout(history));
    return;
  }
  dispatch(loginConfirmedAction(tokenDetailsString));
}
