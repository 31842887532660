import React, { useState } from "react";
import { Dropdown, Modal } from "react-bootstrap";
import { addCategoryApi } from "../components/APIs/Api";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Button from "../components/Button";

export default function AddCategory({ show, onHide, categoryLists, history }) {
  const [file, setFile] = useState();
  const [name, setName] = useState();
  const [nameError, setNameError] = useState("");
  const [loader, setLoader] = useState(false);

  async function handleAddFormSubmit(event) {
    event.preventDefault();

    if (name.length < 3) {
      setNameError("Enter valid name");
      return;
    }
    setLoader(true);
    const res = await addCategoryApi(name, file);
    setLoader(false);
    if (res?.status === 200) {
      setLoader(false);
      categoryLists();
      onHide(false);
      toast.success("Added Success !", {
        position: toast.POSITION.TOP_RIGHT,
      });
    } else {
      setLoader(false);
      toast.error(res?.data?.error?.error, {
        position: toast.POSITION.TOP_,
      });
    }
  }

  return (
    <Modal className="sortBy" show={show} onHide={onHide} centered>
      <Modal.Header className="fp-modal mt-3">
        <h4 className="fs-20 text-black fw-600">Add Category Details</h4>
        <button type="button" className="close" onClick={() => onHide()}>
          <span>×</span>
        </button>
      </Modal.Header>
      <Modal.Body>
        <form onSubmit={handleAddFormSubmit}>
          <div className="add-contact-box">
            <div className="add-contact-content">
              <div className="form-group mb-3">
                <label className="text-black font-w500">Image</label>
                <div className="mb-3">
                  <input
                    type="file"
                    accept=".png, .jpg, .jpeg"
                    required="required"
                    onChange={(e) => setFile(e.target.files[0])}
                  />
                </div>
                <label className="text-black font-w500">Category Name</label>
                <div className="contact-name">
                  <input
                    type="text"
                    className="form-control"
                    name="Date_Join"
                    required="required"
                    onChange={(e) => {
                      setName(e.target.value);
                      setNameError();
                    }}
                    placeholder="category"
                  />
                  <span className="text-danger">{nameError}</span>
                </div>
              </div>
            </div>
          </div>
          <Modal.Footer className="mb-3 d-flex justify-content-end gap-2">
            <Button loader={loader} />
          </Modal.Footer>
        </form>
      </Modal.Body>
    </Modal>
  );
}
